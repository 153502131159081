.w09f4000-user-image {
  transition: all .4s;
}
.w09f4000-user-image-hover-item {
  transform: scale(1.3);
  margin-left: 0 !important;
  margin-right: 8px !important;
}

.custom-orgchart {
  display: flex;
  justify-content: center;
  .backdrop-drag {
    width: 5000px;
    height: 5000px;
    position: absolute;
    top: -1000px;
    left: 1000px
  }
  org-chart {
    min-width: auto;
    min-height: auto;
    background-color: #F4F4F4;
    cursor: move !important;
    .node {
      width: auto;
      &:hover, &.focused {
        background-color: transparent;
      }
    }
    tr.lines {
      td {
        border-color: #9597A1 !important;
        height: 32px;
      }
      .downLine {
        background-color: #9597A1;
        height: 32px;
      }
      .spanNum {
        position: relative;
        background: #747474;
        top: 15px;
        display: inline-block;
        line-height: 0;
        border-radius: 50%;
        border: 2px solid #FFFFFF;
        ::after {
          content: "";
          position: absolute;
          border: 1px solid #9597a1;
          height: 20px;
          top: -20px;
          left: 46%;
        }
        span {
          font-weight: 500;
          font-size: 14px;
          color: #FFFFFF;
          display: inline-block;
          min-width: 24px;
          line-height: 24px;
          height: 24px;
        }
      }
    }
    .edge {
      display: none;
    }
    .nodeIconRed {
      background-color: #111D5E;
    }
    .textHidden {
      font-size:   12px;
      font-weight: 500;
      color:  #7F828E;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}